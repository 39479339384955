import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Elem from '../common/subNavElem';
import { navigate } from "gatsby";

const Wrapper = styled.div`
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: row;
`

const Nav = () => {

    const [pathname, setPathname] = useState('/');

    useEffect(() => {
        setPathname(window.location.pathname);
    },[setPathname]);

    return (
        <Wrapper>
            <Elem onClick={() => navigate('/anfahrt')} active={pathname === '/anfahrt'}>Anfahrt</Elem>
            <Elem onClick={() => navigate('/kontakt')} active={pathname === '/kontakt'}>Kontakt</Elem>
        </Wrapper>
    )
};

export default Nav;